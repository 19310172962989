import { forwardRef } from "react";

import type { Props as BaseLinkProps } from "scmp-app/components/common/base-link";
import { BaseLink } from "scmp-app/components/common/base-link";
import { routes } from "scmp-app/lib/router";
import { getIsSubSection, shouldRedirectBaseDynamicSectionLink } from "scmp-app/lib/router/helpers";
import { RouteType, useRouteDetector } from "scmp-app/lib/router/hooks";
import { getAbsoluteUrl } from "scmp-app/lib/utils";

import type { LinkEntity } from "./types";

export type Props = {
  className?: string;
  linkEntity: LinkEntity;
} & Omit<BaseLinkProps, "as" | "href" | "pathname">;

export const BaseEntityLink = forwardRef<HTMLAnchorElement, Props>((properties, reference) => {
  const { children, hash, linkEntity, query: queryProps, target, ...remainingProps } = properties;
  const renderLink = ({
    hash,
    isAnchor,
    linkAs,
    pathname,
    query,
    target = "_self",
  }: {
    hash?: BaseLinkProps["hash"];
    isAnchor?: boolean;
    linkAs?: BaseLinkProps["as"];
    pathname: string;
    query?: BaseLinkProps["query"];
    target?: string;
  }) => (
    <BaseLink
      {...remainingProps}
      anchorProps={{ target }}
      as={linkAs}
      hash={hash}
      isAnchor={isAnchor}
      pathname={pathname}
      query={{ ...query, ...queryProps }}
      ref={reference}
    >
      {children}
    </BaseLink>
  );

  const {
    entityId,
    parentSection,
    routeType: routeTypeInEntity = "",
    urlAlias,
    urlRedirectPath,
  } = linkEntity;
  const defaultRenderLink = renderLink({ pathname: urlAlias });
  const renderMap = {
    [RouteType.DrupalRedirect]: () => renderLink({ pathname: getAbsoluteUrl(urlAlias) }),
    [RouteType.Dynamic]: () => {
      const typeName =
        routeTypeInEntity === "Section" &&
        getIsSubSection({
          entityId,
          parentSection,
        })
          ? "SubSection"
          : routeTypeInEntity;

      const matched = routes.dynamics[typeName];
      const { pathname, query } = matched.route(entityId);

      return renderLink({
        hash,
        isAnchor: shouldRedirectBaseDynamicSectionLink(pathname, queryProps),
        linkAs: urlAlias,
        pathname,
        query,
        target,
      });
    },
    [RouteType.ExternalDomain]: () => renderLink({ pathname: urlAlias, target: "_blank" }),
    [RouteType.InternalDomain]: () => defaultRenderLink,
    [RouteType.Other]: () => defaultRenderLink,
    [RouteType.Redirect]: () => renderLink({ pathname: getAbsoluteUrl(urlRedirectPath) }),
    [RouteType.Static]: () => defaultRenderLink,
  };
  const { getRouteTypeByRouteEntity } = useRouteDetector();
  return renderMap[getRouteTypeByRouteEntity(linkEntity)]?.();
});

BaseEntityLink.displayName = "BaseEntityLink";
